import React from "react";
import Section from "./section";

export default () => (
  <div style={{ padding: 20 }}>
    <h2>Datenschutz-Bestimmungen</h2>
    <br />
    <div>
      <b>
        Taxionspot Muttergesellschaft von Schnelleintaxi, mit Sitz in
        Houckgeeststraat ist verantwortlich für die Verarbeitung von
        personenbezogenen Daten, wie in dieser Datenschutzerklärung gezeigt.
      </b>
    </div>
    <br />
    <Section heading="Kontaktinformationen:">
      <a href="https://www.taxionspot.nl/">
        https://www.Taxionspot und Schnelleintaxi.nl/
      </a>
      <br />
      KVK: 65752376
      <br />
      +310402405588
      <br />
      Sabur Mortazawi ist Datenschutzbeauftragter von Taxionspot und
      Schnelleintaxi und Schnelleintaxi. Sie erreichen Sie über
      support@Taxionspot und service@schnelleintaxi.de
    </Section>
    <Section heading="Personenbezogene Daten, die wir verarbeiten">
      <p>
        Taxionspot und Schnelleintaxi verarbeitet Ihre personenbezogenen Daten,
        indem sie unsere Dienste nutzen und/oder indem Sie sie uns selbst zur
        Verfügung stellen. Nachfolgend finden Sie eine Übersicht über die
      </p>
      <p>personenbezogene Daten, die wir verarbeiten:</p>
      <ui>
        <li>Vor- und Nachname</li>
        <li>Adressdetails</li>
        <li>Telefonnummer</li>
        <li>E-Mail-Adresse</li>
        <li>
          Andere personenbezogene Daten, die Sie aktiv zur Verfügung stellen, z.
          B. durch Die Erstellung eines Profils auf dieser Website, in
          Korrespondenz und telefonisch
        </li>
      </ui>
    </Section>
    <Section heading="Spezielle und/oder sensible personenbezogene Daten, die wir verarbeiten">
      Unsere Website und/oder unser Dienst beabsichtigen nicht, Daten über
      Website-Besucher unter 16 Jahren zu sammeln. Es sei denn, sie haben die
      Erlaubnis von Eltern oder Erziehungsberechtigten. Wir können jedoch nicht
      überprüfen, ob ein Besucher über 16 Jahre alt ist. Wir raten Eltern daher,
      sich an den Online-Aktivitäten ihrer Kinder zu beteiligen, um zu
      verhindern, dass Daten über Kinder ohne Zustimmung der Eltern gesammelt
      werden. Wenn Sie davon überzeugt sind, dass wir ohne diese Zustimmung
      personenbezogene Daten über einen Minderjährigen gesammelt haben,
      kontaktieren Sie uns bitte über Support@Taxionspot und Schnelleintaxi.nl
      werden wir diese Informationen löschen. Zu welchem Zweck und auf welcher
      Grundlage verarbeiten wir personenbezogene Daten
    </Section>
    <Section
      heading="Taxionspot und Schnelleintaxi verarbeitet Ihre personenbezogenen Daten für folgende
Zwecke:"
    >
      <ui>
        <li>Abwicklung Ihrer Zahlung</li>
        <li>Versand unseres Newsletters und/oder unserer Werbebroschüre</li>
        <li>
          Um Sie anrufen oder per E-Mail senden zu können, wenn dies für die
          Erfüllung unserer Dienstleistungen erforderlich ist
        </li>
        <li>
          Informieren Sie über Änderungen unserer Dienstleistungen und Produkte
        </li>
        <li>Erlauben Sie Ihnen, ein Konto zu erstellen</li>
        <li>Um Waren und Dienstleistungen an Sie zu liefern</li>
        <li>
          Taxionspot und Schnelleintaxi analysieren Ihr Verhalten auf der
          Website, um die Website zu verbessern und das Angebot an Produkten und
          Dienstleistungen an Ihre Vorlieben anzupassen.
        </li>
      </ui>
    </Section>
    <Section heading="Automatisierte Entscheidungsfindung">
      Taxionspot und Schnelleintaxi treffen Entscheidungen auf der Grundlage der
      automatisierten Verarbeitung in Angelegenheiten, die (erhebliche) Folgen
      für den Einzelnen haben können. Dies sind Entscheidungen, die von
      Computerprogrammen oder Systemen getroffen werden, ohne einen Menschen
      (z.B. ein Mitarbeiter von Taxionspot und Schnelleintaxi)dazwischen.
      Taxionspot und Schnelleintaxi verwendet die folgenden Computerprogramme
      oder Systeme: Automatisiertes System wird verwendet, um die angegebenen
      Informationen an den nächstgelegenen Fahrer zu senden. Dadurch weiß der
      Fahrer, wo er den Kunden abholen und absetzen muss, auch der Fahrer kann
      sich an den Kunden wenden, wenn etwas schief geht.
    </Section>
    <Section heading="Wie lange wir personenbezogene Daten aufbewahren">
      Taxionspot und Schnelleintaxi speichern Ihre personenbezogenen Daten nicht
      länger als unbedingt erforderlich, um die Ziele zu erreichen, für die Ihre
      Daten erhoben werden. Für die folgenden (Kategorien) personenbezogener
      Daten verwenden wir die folgenden Aufbewahrungsfristen:
      <br />
      <table style={{ border: "1px solid", padding: 5 }}>
        <thead style={{ background: "#eee" }}>
          <tr style={{ border: "1px solid", padding: 5, fontWeight: "bold" }}>
            <td>Kategorie </td>
            <td style={{ border: "1px solid", padding: 5 }}>
              Aufbewahrungszeitraum
            </td>
            <td>Grund</td>
          </tr>
        </thead>
        <tbody>
          <tr style={{ border: "1px solid", padding: 5 }}>
            <td>Persönliche Daten</td>
            <td style={{ border: "1px solid", padding: 5 }}>Max: 2 Jahre</td>
            <td>
              Die Daten werden bei Komplikationen gespeichert, die Verwaltung
              kann zurückblicken. Es schaut auch wöchentlich zurück, welche
              Kunden der Fahrer mitgebracht hat, um eine Zahlung an den Fahrer
              zu leisten.
            </td>
          </tr>
          <tr>
            <td>Koordiniert</td>
            <td style={{ border: "1px solid", padding: 5 }}>Max: 2 Jahre</td>
            <td>
              Die Daten werden bei Komplikationen gespeichert, die Verwaltung
              kann zurückblicken. Es schaut auch wöchentlich zurück, welche
              Kunden der Fahrer mitgebracht hat, um eine Zahlung an den Fahrer
              zu leisten.
            </td>
          </tr>
        </tbody>
      </table>
    </Section>
    <Section heading="Cookies oder ähnliche Techniken, die wir verwenden">
      Taxionspot und Schnelleintaxi verwenden nur technische und funktionale
      Cookies. Und analytische Cookies, die nicht in Ihre Privatsphäre
      eindringen. Ein Cookie ist eine kleine Textdatei, die beim ersten Besuch
      dieser Website auf Ihrem Computer, Tablet oder Smartphone gespeichert
      wird. Die von uns verwendeten Cookies sind für den technischen Betrieb der
      Website und Ihre Benutzerfreundlichkeit erforderlich. Sie stellen sicher,
      dass die Website ordnungsgemäß funktioniert und erinnern sich
      beispielsweise an Ihre bevorzugten Einstellungen. Es ermöglicht uns auch,
      unsere Website zu optimieren. Sie können Cookies deaktivieren, indem Sie
      Ihren Internetbrowser so einstellen, dass cookies nicht mehr gespeichert
      werden. Darüber hinaus können Sie auch alle Informationen löschen, die
      zuvor über die Einstellungen Ihres Browsers gespeichert wurden.
    </Section>
    <Section heading="Anzeigen, Ändern oder Löschen von Daten">
      Sie haben das Recht, Ihre personenbezogenen Daten einzusehen, zu
      berichtigen oder zu löschen. Darüber hinaus haben Sie das Recht, Ihre
      einwillige Einwilligung zur Datenverarbeitung zu widerrufen oder der
      Verarbeitung Ihrer personenbezogenen Daten durch Taxionspot und
      Schnelleintaxi zu widersprechen und Sie haben das Recht auf
      Datenübertragbarkeit. Das bedeutet, dass Sie uns eine Anfrage untersenden
      können, um die personenbezogenen Daten, die wir über Sie haben, in einer
      Computerdatei an Sie oder eine andere von Ihnen erwähnte Organisation zu
      senden. Sie können einen Antrag auf Zugang, Berichtigung, Löschung,
      Datennutzung Ihrer personenbezogenen Daten oder Aufforderung, Ihre
      Einwilligung zu widerrufen oder der Verarbeitung Ihrer personenbezogenen
      Daten zu widersprechen, an Support@Taxionspot und service@schnelleintaxi.
      die. Um sicherzustellen, dass die Anfrage für den Zugang von Ihnen
      gestellt wurde, bitten wir Sie, eine Kopie Ihrer ID mit der Anfrage zu
      senden. Verdunkeln Sie in dieser Kopie Ihr Passfoto, MRZ (maschinenlesbare
      Zone, der Streifen mit Denben am unteren Rand des Reisepasses), Passnummer
      und Sozialversicherungsnummer (BSN). Dies dient dem Schutz Ihrer
      Privatsphäre. Wir werden so schnell wie möglich, aber innerhalb von vier
      Wochen auf Ihre Anfrage antworten. Taxionspot und Schnelleintaxi weisen
      auch darauf hin, dass Sie die Möglichkeit haben, eine Beschwerde bei der
      nationalen Aufsichtsbehörde, der Behörde für personenbezogene Daten. Dies
      kann über den folgenden Link erfolgen:
      <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons">
        https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
      </a>
    </Section>
    <Section heading="Wie wir personenbezogene Daten schützen">
      Taxionspot und Schnelleintaxi nehmen den Schutz Ihrer Daten ernst und
      ergreifen geeignete Maßnahmen, um Missbrauch, Verlust, unbefugten Zugriff,
      unerwünschte Offenlegung und unbefugte Änderungen zu verhindern. Wenn Sie
      der Meinung sind, dass Ihre Daten nicht ordnungsgemäß geschützt sind oder
      Hinweise auf Missbrauch vorliegen, wenden Sie sich bitte an unseren
      Kundenservice oder Support@Taxionspot und service@schnelleintaxi.de
    </Section>
    <br />
  </div>
);
