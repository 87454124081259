import React from "react";
import Section from "./section";

export default () => (
  <div style={{ padding: 20 }}>
    <h2>Privacy Policy</h2>
    <br />
    <div>
      <b>
        Taxionspot,located at Houckgeeststraat, is responsible for the
        processing of personal data as shown in this privacy statement.
      </b>
    </div>
    <br />
    <Section heading="Contact information">
      <a href="https://www.taxionspot.nl/">https://www.taxionspot.nl/</a>
      <br />
      KVK: 65752376
      <br />
      +310402405588
      <br />
      Sabur Mortazawi is the Data Protection Officer of Taxionspot.. It can be
      reached by support@taxionspot.nl
    </Section>
    <Section heading="Personal data that we process">
      <p>
        Taxionspot processes your personal data by using our services and/or
        because you provide it to us yourself. Below is an overview of the
      </p>
      <p>personal data that we process:</p>
      <ui>
        <li>First and last name</li>
        <li>Address details</li>
        <li>Phone number</li>
        <li>Email address</li>
        <li>
          Other personal data that you actively provide, for example by creating
          a profile on this website, in correspondence and by telephone
        </li>
      </ui>
    </Section>
    <Section heading="Special and/or sensitive personal data that we process">
      Our website and/or service does not intend to collect data on website
      visitors under the age of 16. Unless they have permission from parents or
      guardians. However, we cannot verify that a visitor is over 16. We
      therefore advise parents to be involved in their children's online
      activities in order to prevent data about children being collected without
      parental consent. If you are satisfied that we have collected personal
      information about a minor without that consent, please contact us through
      Support@taxionspot.nl, we will delete this information. For what purpose
      and on the basis of what basis we process personal data
    </Section>
    <Section heading="Taxionspot processes your personal data for the following purposes">
      <ui>
        <li>Handling your payment</li>
        <li>Sending our newsletter and/or advertising leaflet</li>
        <li>
          To be able to call or e-mail you if this is necessary to perform our
          services
        </li>
        <li>Inform you about changes to our services and products</li>
        <li>Allow you to create an account</li>
        <li>To deliver goods and services to you</li>
        <li>
          Taxionspot analyses your behaviour on the website in order to improve
          the website and tailor the range of products and services to your
          preferences.
        </li>
      </ui>
    </Section>
    <Section heading="Automated decision-making">
      Taxionspot makes decisions based on automated processing on matters that
      can have (significant) consequences for individuals. These are decisions
      that are made by computer programs or systems, without a human being (for
      example, an employee of Taxionspot)in between. Taxionspot uses the
      following computer programs or systems: Automated system is used to send
      the specified information to the nearest driver. As a result, the driver
      knows where to pick up and drop off the customer, also the driver can
      contact the customer if something goes wrong.
    </Section>
    <Section heading="How long we keep personal data">
      Taxionspot does not store your personal data for longer than is strictly
      necessary in order to achieve the goals for which your data is collected.
      We use the following retention periods for the following (categories) of
      personal data:
      <br />
      <table style={{ border: "1px solid", padding: 5 }}>
        <thead style={{ background: "#eee" }}>
          <tr style={{ border: "1px solid", padding: 5, fontWeight: "bold" }}>
            <td>Category</td>
            <td style={{ border: "1px solid", padding: 5 }}>
              Retention period
            </td>
            <td>Reason</td>
          </tr>
        </thead>
        <tbody>
          <tr style={{ border: "1px solid", padding: 5 }}>
            <td>Personal data</td>
            <td style={{ border: "1px solid", padding: 5 }}>Max: 2 years</td>
            <td>
              The data is retained when complications arise, the administration
              can look back. It also looks back weekly which customers the
              driver has brought away to make a payment to the driver.
            </td>
          </tr>
          <tr>
            <td>Coordinated</td>
            <td style={{ border: "1px solid", padding: 5 }}>Max: 2 years</td>
            <td>
              The data is retained when complications arise, the administration
              can look back. It also looks back weekly which customers the
              driver has brought away to make a payment to the driver.
            </td>
          </tr>
        </tbody>
      </table>
    </Section>
    <Section heading="Cookies, or similar techniques, that we use">
      Taxionspot only uses technical and functional cookies. And analytical
      cookies that don't invade your privacy. A cookie is a small text file that
      is stored on your computer, tablet or smartphone on the first visit to
      this website. The cookies we use are necessary for the technical operation
      of the website and your ease of use. They make sure the website works
      properly and, for example, remember your preferred settings. It also
      allows us to optimize our website. You can opt out of cookies by setting
      your internet browser to stop storing cookies. In addition, you can also
      delete any information previously stored through your browser's settings.
    </Section>
    <Section heading="View, modify, or delete data">
      You have the right to view, correct or delete your personal data. In
      addition, you have the right to revoke your possible consent for the data
      processing or to object to the processing of your personal data by
      Taxionspot and you have the right to data transferability. This means that
      you can submit a request to us to send the personal data that we have
      about you in a computer file to you or another organisation you have
      mentioned. You may send a request for access, correction, deletion, data
      isocation of your personal data or request to withdraw your consent or
      object to the processing of your personal data to Support@taxionspot.nl.
      To be sure that the request for access has been made by you, we ask you to
      send a copy of your ID with the request. In this copy, black out your
      passport photo, MRZ (machine readable zone, the strip with numbers at the
      bottom of the passport), passport number and Social Security number (BSN).
      This is to protect your privacy. We will respond as soon as possible, but
      within four weeks, at your request. Taxionspot would also like to point
      out that you have the opportunity to file a complaint with the national
      supervisory authority, the Personal Data Authority. This can be done via
      the following link:
      <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons">
        https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
      </a>
    </Section>
    <Section heading="How we protect personal data">
      Taxionspot takes the protection of your data seriously and takes
      appropriate measures to prevent abuse, loss, unauthorized access, unwanted
      disclosure and unauthorized modification. If you feel that your data is
      not secure or there are indications of abuse, please contact our customer
      service or Support@taxionspot.nl
    </Section>
    <br />
  </div>
);
